@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap');

.hero-svg-overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 0;
  box-shadow: 0 8px 20px -10px rgba(0, 0, 0, 0.8);
}

.hero-svg-pane {
  fill: rgba(255, 255, 255, 0.94);
  width: 100%;
}

.hero-svg-logo {
  color: #000;
}

.hero-svg-pane-masking {
  fill: #fff;
  -webkit-mask: url(#masking);
  mask: url(#masking);
}

.hero-svg-text {
  font-family: 'Mr Dafoe', cursive;
  // font-family: 'Open sans', sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 3rem;
  text-anchor: middle;
  letter-spacing: -0.03em;
  word-spacing: 0.15em;
  /* opacity: 0; for animation */
}

.hero-svg-text--main {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  text-anchor: middle;
  // text-transform: uppercase;
}

.hero-svg-text--copyright {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  text-anchor: middle;
  text-transform: uppercase;
}

.scroll-arrow-lines {
  fill: none;
  stroke: #000;
  stroke-width: 4;
  stroke-linejoin: round;
  stroke-linecap: round;
  animation: scrollArrow 3s ease infinite;
}

.scroll-arrow-text {
  fill: #000;
  text-anchor: middle;
  font-family: 'lato', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: -0.03em;
  word-spacing: 0.15em;
  text-transform: uppercase;
  animation: scrollArrow 3s ease infinite;
}

.scroll-arrow-animated {
  animation: scrollArrow 3s ease infinite;
}

@keyframes scrollArrow {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
